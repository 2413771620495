<template>
  <div class="login">
    <div class="box col-pc-4 col-smobile-12 uk-margin-auto">
      <div class="middle col-pc-10 col-smobile-12 col-mobile-10 col-margin-auto">
        <div class="logo">
          <img
            class="uk-margin-small-top uk-margin-small-bottom"
            src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png"
            style="max-width: 120px;"
            alt="logo"
          />
        </div>
        <form class="form col-pc-12 col-smobile-12">
          <div class="banner orange error">
            <span uk-icon="icon: warning"></span>
            <p class="uk-margin-left">{{ $t('moving-on.attention-required') }}</p>
          </div>
          <hr>
          <p>{{ $t('moving-on.dear-clients') }}</p>
          <p>{{ $t('moving-on.we-are-closing') }}</p>
          <p>{{ $t('moving-on.we-are-closing-1') }} (<a href="https://onclock.xyz">https://onclock.xyz</a>)</p>
          <p>{{ $t('moving-on.we-are-closing-2') }}</p>
          <p>{{ $t('moving-on.we-are-closing-4') }}</p>
          <p>{{ $t('moving-on.we-are-closing-5') }}</p>
          <p class="uk-margin-large-top"><i>{{ $t('moving-on.do-not-open-a-ticket') }}</i></p>
          <button
            class="primary"
            @click.prevent="openOnClock"
          >{{ $t('moving-on.understood') }}</button>
        </form>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/login/login.less"></style>

<script>
export default {
  name: 'MovingOn',
  methods: {
    openOnClock() {
      window.location.replace('https://onclock.xyz')
    }
  }
}
</script>
